<template>
  <div class="pages">
    <div class="boxs">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-swipe-cell v-for="item in list" :key="item.id">
          <van-cell :border="false" :title="item.sTitle">
            <template #right-icon>
              <el-button size="mini" type="warning" :disabled="item.bShow != 1" @click="copy(item.sCode)"><van-icon
                  name="share" /></el-button>
            </template>
            <template #title>
              <div style="height: 28px; line-height: 28px" @click="showDetail(item.sCode)">
                {{ item.sTitle }}
              </div>
            </template>
          </van-cell>
          <template #right>
            <van-button square type="danger" text="删除" @click="deletePost(item)" />
            <van-button square :type="item.bShow == 1 ? 'info' : 'primary'" :text="item.bShow == 1 ? '隐藏' : '分享'"
              @click="turnShow(item)" />
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
    <div class="push">
      <i class="el-icon-circle-plus" @click="upgrade" v-if="!(upgradePage || detailPage)"></i>
    </div>
    <transition name="el-zoom-in-bottom">
      <div v-show="upgradePage" class="ePage">
        <div class="top">
          <div class="closeButton" @click="upgradePage = false">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="dPage">
          <div class="fPage">
            <el-input v-model="title" placeholder="标题" maxlength="36"></el-input>
            <el-upload class="upload-box" action="http://www.kuroneko.vip:7086/Operate/upload/" name="photos"
              :file-list="fileList" list-type="picture" multiple :http-request="uploadFile" accept="image/*" :limit="45"
              :on-exceed="handleExceed">
              <el-button type="primary" style="margin-top: 20px; width: 100%">点击上传</el-button>
            </el-upload>
          </div>
        </div>
        <div class="nowToUp">
          <div class="check">
            <el-checkbox v-model="shared">分享</el-checkbox>
          </div>
          <div class="button">
            <el-button type="warning" style="width: 100%" @click="createPost">发布</el-button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="el-zoom-in-bottom">
      <div v-show="detailPage" class="ePage">
        <div class="top">
          <div class="closeButton" @click="closeDetail">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="sPage">
          <div class="tPage">
            {{ detail.sTitle }}
          </div>
          <div class="uPage">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px">
                    <van-icon name="star" />
                  </div>
                  <div class="number">{{ detail.starNumber }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px">
                    <van-icon name="good-job" />
                  </div>
                  <div class="number">{{ 0 }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px">
                    <van-icon name="delete" />
                  </div>
                  <div class="number">{{ 0 }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="vPage">
            <van-image style="padding: 2.5px 5px" width="calc(100% - 10px)" v-for="(item, index) in imageList"
              :key="index" :src="item" @click="showBig(index)" loading-icon="clock" />
          </div>
        </div>
      </div>
    </transition>
    <Loading :show.sync="ifLoad" :loaded.sync="unit" :all.sync="imageList.length" />
  </div>
</template>

<script>
import { Notify } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import Loading from "../comm/loading";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      upgradePage: false,
      detailPage: false,
      title: "",
      fileList: [],
      fileCount: 0,
      fileCode: "",
      shared: true,
      page: 1,
      detail: {
        sTitle: "",
        starNumber: 0,
      },
      imageList: [],
      ifLoad: false,
      cancelTokens: [],
      unit: 0,
    };
  },
  beforeDestroy() {
    if (this.imageList.length != 0) {
      for (const item in this.imageList) {
        URL.revokeObjectURL(item);
      }
    }
    this.cancelTokens.forEach((source) => {
      source.cancel("Component is being destroyed");
    });
  },
  methods: {
    async copy(text) {
      let copyInput = document.createElement("input");
      copyInput.value = text;
      copyInput.setAttribute("readonly", "readonly"); //避免ios端聚焦引起的白屏抖动
      document.body.appendChild(copyInput); //插入body
      copyInput.select(); //选择对象
      // copyInput.setSelectionRange(0, link.length);//ios端使用setSelectionRange避免选择不全问题
      document.execCommand("Copy"); //执行复制命令
      this.copySuccess = true;
      copyInput.remove(); //移除
      Notify({ type: "success", message: "内容已复制到剪贴板" });
    },
    onLoad() {
      this.$reqTools.request("getMyPost", { page: this.page }).then((res) => {
        this.loading = false;
        if (res.code == 500) {
          Notify({ type: "danger", message: res.msg });
          this.$router.push("login");
        } else if (res.code == 203) {
          Notify({ type: "danger", message: res.msg });
          this.finished = true;
        } else {
          this.list.push(...res.data);
          this.page++;
          if (res.data.length < 10) {
            this.finished = true;
          }
        }
      });
    },
    closeDetail() {
      this.detailPage = false;
      this.imageList = [];
      if (this.imageList.length != 0) {
        for (const item in this.imageList) {
          URL.revokeObjectURL(item);
        }
      }
    },
    createPost() {
      if (this.title == "") {
        Notify({ type: "warning", message: "标题不能为空" });
        return;
      } else {
        this.$reqTools
          .request("createPost", {
            sTitle: this.title,
            sCode: this.fileCode,
            sDate: this.formatDate(new Date()),
            bShow: this.shared,
          })
          .then((res) => {
            if (res.code != 200) {
              Notify({ type: "danger", message: res.msg });
              this.$router.push("login");
            } else {
              Notify({ type: "success", message: "上传成功" });
              this.upgradePage = false;
              this.page = 1;
              this.list = [];
              this.onLoad();
            }
          });
      }
    },
    async uploadFile({ file }) {
      const formData = new FormData();
      this.fileCount += 1; // 每次上传时递增顺序号
      const fileExtension = file.name.split(".").pop(); // 获取文件扩展名
      const newFileName = `${this.fileCount}.${fileExtension}`; // 新的文件名
      const newFile = new File([file], newFileName, { type: file.type });
      // const compressedFile = await this.compressImage(newFile, 800, 800, 0.7);
      formData.append("photos", newFile);
      fetch(
        `http://frp-bar.top:54353/Operate/upload/?sCode=${this.fileCode}`,
        {
          method: "POST",
          body: formData,
          headers: { session: sessionStorage.getItem("session") },
        }
      ).then((response) => {
        if (response.status == "200") {
          console.log("上传成功");
        } else {
          console.error("上传失败");
        }
      });
    },
    turnShow(item) {
      this.$reqTools
        .request("showPost", {
          sCode: item.sCode,
          bShow: item.bShow == 1 ? 0 : 1,
        })
        .then((res) => {
          if (res.code != 200) {
            Notify({ type: "danger", message: res.msg });
          } else {
            this.list = this.list.map((obj) => {
              if (obj.sCode == item.sCode) {
                return { ...obj, bShow: item.bShow == 1 ? 0 : 1 };
              }
              return obj;
            });
          }
        });
    },
    deletePost(item) {
      Dialog.confirm({
        title: "提示",
        message: "确认是否删除此贴",
      }).then(() => {
        this.$reqTools
          .request("deletePost", {
            sCode: item.sCode,
          })
          .then((res) => {
            if (res.code != 200) {
              Notify({ type: "danger", message: res.msg });
            } else {
              this.list = this.list.filter((it) => it.sCode != item.sCode);
            }
          });
      });
    },
    randomCode(n) {
      let string = "";
      for (let i = 0; i < n;) {
        let a = Math.floor(Math.random() * (123 - 48)) + 48;
        let str = String.fromCharCode(a);
        if (/^[a-zA-Z0-9]+$/.test(str)) {
          i++;
          string += String(str);
        }
      }
      return string;
    },
    compressImage(file, maxWidth, maxHeight, quality = 0.8) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;
            if (width > maxWidth || height > maxHeight) {
              if (width > height) {
                height = Math.floor((height * maxWidth) / width);
                width = maxWidth;
              } else {
                width = Math.floor((width * maxHeight) / height);
                height = maxHeight;
              }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File(
                  [blob],
                  file.name.replace(/\.\w+$/, ".jpg"),
                  {
                    type: "image/jpeg",
                    lastModified: Date.now(),
                  }
                );
                resolve(compressedFile);
              },
              file.type, // 保持原来的文件类型
              quality // 设置图片质量，范围0-1
            );
          };
          img.onerror = (error) => reject(error);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    formatDate(date) {
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      var year = date.getFullYear();
      var month = padZero(date.getMonth() + 1); // 月份是从0开始的
      var day = padZero(date.getDate());
      return year.toString() + month.toString() + day.toString();
    },
    upgrade() {
      this.fileCode = this.randomCode(12);
      this.title = "";
      this.fileCount = 0;
      this.fileList = [];
      this.shared = true;
      this.imageList = [];
      this.upgradePage = true;
    },
    handleExceed(files, fileList) {
      Notify({ type: "warning", message: "上传数量不能超过45张" });
    },
    showDetail(sCode) {
      this.ifLoad = true;
      this.unit = 0;
      this.$reqTools.request("showDetail", { sCode: sCode }).then((res) => {
        this.detail = res.data.stats;
        let photo = res.data.sPhoto.sort(
          (a, b) => a.split(".")[0] - b.split(".")[0]
        );
        this.getPhoto(sCode, photo, this.unit)
        if (photo.length != 0) {
          this.imageList = new Array(photo.length);
        }
      });
    },
    async getPhoto(sCode, photo, unit) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.cancelTokens.push(source);
      await this.$reqTools
        .blob("getPhoto", { sCode: sCode, imageName: photo[unit] }, source)
        .then((blob) => {
          let temp = URL.createObjectURL(blob);
          this.imageList.splice(unit, 1, temp);
          let has = this.imageList.filter(Boolean);
          this.unit++;
          if (has.length == photo.length) {
            this.detailPage = true;
            this.ifLoad = false;
          }
          else {
            this.getPhoto(sCode, photo, this.unit)
          }
        });
    },
    showBig(index) {
      ImagePreview({
        images: this.imageList,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  position: relative;
  height: calc(100% - 96px);

  .boxs {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  .push {
    font-size: 50px;
    color: #409eff;
    position: fixed;
    bottom: 60px;
    right: 20px;
  }

  .ePage {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: right;
      margin-right: 5px;

      .closeButton {
        border-left: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-radius: 0 0 10px 10px;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 25px;
      }
    }

    .dPage {
      flex: 1;
      overflow-y: auto;
      position: relative;

      .fPage {
        width: 80%;
        margin-left: 10%;
        margin-top: 20px;
        height: calc(100% - 40px);
        overflow-y: auto;

        /deep/.upload-box {
          >div:nth-child(1) {
            width: 100%;
          }
        }
      }
    }

    .sPage {
      width: 80%;
      margin-left: 10%;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #efefe0;

      .tPage {
        margin: 10px;
      }

      .uPage {
        .star {
          text-align: center;

          .number {
            font-size: 12px;
            color: #666;
          }
        }
      }

      .vPage {
        overflow-y: auto;
        height: 77%;
      }
    }

    .nowToUp {
      display: flex;
      height: 40px;
      width: 80%;
      margin-left: 10%;
      margin-bottom: 20px;

      .check {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button {
        flex: 1;
      }
    }
  }
}
</style>