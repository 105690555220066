<template>
  <div class="page">
    <div class="logo">
      <el-image
        style="width: 100px; height: 100px"
        :src="require('@/assets/logo.png')"
        fit="fill"
      ></el-image>
    </div>
    <div class="loginBox">
      <div class="box">
        <el-form ref="form" label-width="3em">
          <el-form-item label="账号">
            <el-input v-model="sAccount" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="sPassword" clearable show-password></el-input>
          </el-form-item>
          <div style="text-align: center; margin-bottom: 20px">
            <el-checkbox v-model="checked" size="small">记住密码</el-checkbox>
          </div>
          <el-button
            type="primary"
            style="width: 100%"
            @click="login"
            :loading="loading"
            >登录</el-button
          >
          <div class="writeNew">
            <el-link type="success" underline @click="$router.push('sign')"
              >注册账号</el-link
            >
          </div>
        </el-form>
      </div>
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >苏ICP备2021012269号-1</a
      >
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      sAccount: "",
      sPassword: "",
      loading: false,
      checked: false,
    };
  },
  mounted() {
    let has = localStorage.getItem("photoKeep");
    if (has) {
      let temp = JSON.parse(has);
      this.checked = true;
      this.sAccount = temp.sName;
      this.sPassword = temp.sPass;
    }
  },
  methods: {
    login() {
      this.loading = true;
      if (this.sAccount == "" || this.sPassword == "") {
        Notify({ type: "danger", message: "账号或密码不能为空" });
        this.loading = false;
      } else {
        this.$reqTools
          .request(
            "Login",
            { sAccount: this.sAccount, sPassword: this.sPassword },
            false
          )
          .then((res) => {
            this.loading = false;
            if (res.code != 200) {
              Notify({ type: "danger", message: res.msg });
            } else {
              let data = this.toArray(res.data);
              if (data.length != 0) {
                sessionStorage.setItem("userName", data[0].sUserName);
                sessionStorage.setItem("session", data[0].session);
                this.$router.push("main");
                if (this.checked) {
                  localStorage.setItem(
                    "photoKeep",
                    JSON.stringify({
                      sName: this.sAccount,
                      sPass: this.sPassword,
                    })
                  );
                } else {
                  localStorage.removeItem("photoKeep");
                }
              }
            }
          });
      }
    },
    toArray(obj) {
      let array = [];
      for (let a in obj) {
        let temp = obj[a];
        this.$set(temp, "_id", a);
        array.push(temp);
      }
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .logo {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .loginBox {
    flex: 1;
    display: flex;
    justify-content: center;
    .box {
      padding-top: 10vh;
      width: 300px;
      .writeNew {
        text-align: center;
        margin-top: 40px;
        letter-spacing: 0.2em;
        /deep/span {
          font-size: 17px;
        }
      }
    }
  }
  .beian {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > a {
      color: #666;
      text-decoration: none;
      letter-spacing: 0.1em;
    }
  }
}
</style>