import axios from 'axios';
import serveHost from './serveHost';

const webhost = `${location.protocol}//${serveHost()}:7086/`
const devFlag = !~webhost.indexOf(location.hostname) || location.hostname == "localhost";

// let resProxyUrl = "";
// if (devFlag) {
//     resProxyUrl = `/api/`
// } else {
//     resProxyUrl = `${webhost}`
// }

let resProxyUrl = "http://frp-bar.top:54353/";

function request(address, opt, has = true) {
    return new Promise((resolve, reject) => {
        try {
            const headers = {};
            if (has) {
                let session = sessionStorage.getItem("session");
                if (session) {
                    headers.session = session;
                }
            }
            axios({
                method: "post",
                url: resProxyUrl + `Operate/${address}`,
                data: opt,
                headers: headers,
                maxContentLength: Infinity,
                maxBodyLength: Infinity
            }).then((res) => {
                let result = res.data;
                resolve(result);
            }).catch((err) => {
                resolve({ code: err.status, msg: "通信错误" });
            })
        } catch (err) {
            (err);
            reject();
        }

    });
}

function blob(address, opt, cancelSource) {
    return new Promise((resolve, reject) => {
        try {
            let headers = {};
            for (let is in opt) {
                headers[is] = opt[is];
            }
            headers["Content-Type"] = "image/jpeg";
            let session = sessionStorage.getItem("session");
            if (session) {
                headers.session = session;
            }
            const defaultConfig = {
                method: "post",
                url: resProxyUrl + `Operate/${address}`,
                data: opt,
                headers: headers,
                responseType: 'blob',
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
            };
            if (cancelSource) {
                defaultConfig.cancelToken = cancelSource.token;
            }
            axios(defaultConfig).then((res) => {
                let result = res.data;
                resolve(result);
            }).catch((err) => {
                resolve({ code: err.status, msg: "通信错误" });
            })
        } catch (err) {
            (err);
            reject();
        }

    });
}

export default {
    request,
    blob
}