<template>
  <div id="app" :style="{ height: this.height + 'px' }">
    <router-view  v-if="isReload"/>
  </div>
</template>
<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: true,
      height: "100vh",
    };
  },
  created() {
    let pageHeight = window.innerHeight;
    this.height = pageHeight;
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
  },
};
</script>

<style >
body {
  font-family: "微软雅黑"; /*  设置字体 */
  margin: 0px auto; /* 去除上下的边距*/
}
#app {
  width: 100vw;
  background-color: #eee;
}
</style>
