<template>
  <div class="pages">
    <div class="boxs">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-swipe-cell v-for="item in list" :key="item.id">
          <van-cell :border="false" :title="item.sTitle">
            <template #right-icon>
              <el-button size="mini" type="warning" @click="copy(item.sCode)"><van-icon name="share" /></el-button>
            </template>
            <template #title>
              <div style="height: 28px; line-height: 28px" @click="showDetail(item.sCode)">
                {{ item.sTitle }}
              </div>
            </template>
          </van-cell>
        </van-swipe-cell>
      </van-list>
    </div>
    <transition name="el-zoom-in-bottom">
      <div v-show="detailPage" class="ePage">
        <div class="top">
          <div class="closeButton" @click="closeDetail">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="sPage">
          <div class="tPage">
            {{ detail.sTitle }}
          </div>
          <div class="uPage">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="star">
                  <div class="like">
                    <like-effects width="25px" :checked="detail.star">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="star"
                          @click="starIt" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px"><van-icon name="star"
                          @click="starIt" /></span>
                    </like-effects>
                  </div>
                  <div class="number">{{ detail.starNumber }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="like">
                    <like-effects width="25px" :checked="detail.up">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="good-job"
                          @click="hasLike(true)" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px"><van-icon name="good-job"
                          @click="hasLike(true)" /></span>
                    </like-effects>
                  </div>
                  <div class="number">{{ detail.upNumber }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="like">
                    <like-effects width="25px" :checked="detail.down">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="delete"
                          @click="hasLike(false)" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px">
                        <van-icon name="delete" @click="hasLike(false)" />
                      </span>
                    </like-effects>
                  </div>
                  <div class="number">{{ detail.downNumber }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="vPage">
            <van-image style="padding: 2.5px 5px" width="calc(100% - 10px)" v-for="(item, index) in imageList"
              :key="index" :src="item" @click="showBig(index)" loading-icon="clock" />
          </div>
        </div>
      </div>
    </transition>
    <Loading :show.sync="ifLoad" :loaded.sync="unit" :all.sync="imageList.length" />
  </div>
</template>

<script>
import { Notify } from "vant";
import { ImagePreview } from "vant";
import Loading from "../comm/loading";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      upgradePage: false,
      detailPage: false,
      title: "",
      fileList: [],
      fileCount: 0,
      fileCode: "",
      shared: true,
      page: 1,
      detail: {
        sTitle: "",
        star: false,
        starNumber: 0,
        up: false,
        upNumber: 0,
        down: false,
        downNumber: 0,
        sCode: "",
      },
      imageList: [],
      ifLoad: false,
      cancelTokens: [],
      reset: false,
      unit: 0,
    };
  },
  beforeDestroy() {
    if (this.imageList.length != 0) {
      for (const item in this.imageList) {
        URL.revokeObjectURL(item);
      }
    }
    this.cancelTokens.forEach((source) => {
      source.cancel("Component is being destroyed");
    });
  },
  methods: {
    async copy(text) {
      let copyInput = document.createElement("input");
      copyInput.value = text;
      copyInput.setAttribute("readonly", "readonly"); //避免ios端聚焦引起的白屏抖动
      document.body.appendChild(copyInput); //插入body
      copyInput.select(); //选择对象
      // copyInput.setSelectionRange(0, link.length);//ios端使用setSelectionRange避免选择不全问题
      document.execCommand("Copy"); //执行复制命令
      this.copySuccess = true;
      copyInput.remove(); //移除
      Notify({ type: "success", message: "内容已复制到剪贴板" });
    },
    onLoad() {
      this.$reqTools.request("getStar", { page: this.page }).then((res) => {
        this.loading = false;
        if (res.code == 500) {
          Notify({ type: "danger", message: res.msg });
          this.$router.push("login");
        } else if (res.code == 203) {
          Notify({ type: "danger", message: res.msg });
          this.finished = true;
        } else {
          this.list.push(...res.data);
          this.page++;
          if (res.data.length < 10) {
            this.finished = true;
          }
        }
      });
    },
    starIt() {
      let current = this.detail.star;
      this.$reqTools
        .request("star", { sCode: this.detail.sCode })
        .then((res) => {
          if (res.code != 200) {
            this.detail.star = current;
          } else {
            this.reset = true;
            this.detail.star = !current;
            if (this.detail.star) {
              this.detail.starNumber++;
            } else {
              this.detail.starNumber--;
            }
          }
        });
    },
    hasLike(unt) {
      const upTemp = this.detail.up;
      const downTemp = this.detail.down;
      this.$reqTools
        .request("like", { sCode: this.detail.sCode, like: unt })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            res = res.data;
            if (res.up != undefined) {
              this.$set(this.detail, "up", res.up);
            }
            if (res.down != undefined) {
              this.$set(this.detail, "down", res.down);
            }
            if (res.up == false && res.down == undefined) {
              this.detail.upNumber--;
            } else if (res.up == true) {
              if (downTemp) {
                this.detail.downNumber--;
              }
              this.detail.upNumber++;
            } else if (res.down == false && res.up == undefined) {
              this.detail.downNumber--;
            } else if (res.down == true) {
              if (upTemp) {
                this.detail.upNumber--;
              }
              this.detail.downNumber++;
            }
          } else {
            this.$set(this.detail, "up", upTemp);
            this.$set(this.detail, "down", downTemp);
          }
        });
    },
    closeDetail() {
      this.detailPage = false;
      this.imageList = [];
      if (this.reset) {
        this.list = [];
        this.page = 1;
        this.onLoad();
        this.reset = false;
      }
      if (this.imageList.length != 0) {
        for (const item in this.imageList) {
          URL.revokeObjectURL(item);
        }
      }
    },
    turnShow(item) {
      this.$reqTools
        .request("showPost", {
          sCode: item.sCode,
          bShow: item.bShow == 1 ? 0 : 1,
        })
        .then((res) => {
          if (res.code != 200) {
            Notify({ type: "danger", message: res.msg });
          } else {
            this.list = this.list.map((obj) => {
              if (obj.sCode == item.sCode) {
                return { ...obj, bShow: item.bShow == 1 ? 0 : 1 }; // 修改id为1的对象的age属性
              }
              return obj;
            });
          }
        });
    },
    showDetail(sCode) {
      this.ifLoad = true;
      this.unit = 0;
      this.$reqTools.request("showDetail", { sCode: sCode }).then((res) => {
        this.detail = res.data.stats;
        let photo = res.data.sPhoto.sort(
          (a, b) => a.split(".")[0] - b.split(".")[0]
        );
        this.getPhoto(sCode, photo, this.unit)
        if (photo.length != 0) {
          this.imageList = new Array(photo.length);
        }
      });
    },
    async getPhoto(sCode, photo, unit) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.cancelTokens.push(source);
      await this.$reqTools
        .blob("getPhoto", { sCode: sCode, imageName: photo[unit] }, source)
        .then((blob) => {
          let temp = URL.createObjectURL(blob);
          this.imageList.splice(unit, 1, temp);
          let has = this.imageList.filter(Boolean);
          this.unit++;
          if (has.length == photo.length) {
            this.detailPage = true;
            this.ifLoad = false;
          }
          else {
            this.getPhoto(sCode, photo, this.unit)
          }
        });
    },
    showBig(index) {
      ImagePreview({
        images: this.imageList,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  position: relative;
  height: calc(100% - 96px);

  .boxs {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  .ePage {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: right;
      margin-right: 5px;

      .closeButton {
        border-left: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-radius: 0 0 10px 10px;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 25px;
      }
    }

    .dPage {
      flex: 1;
      overflow-y: auto;
      position: relative;

      .fPage {
        width: 80%;
        margin-left: 10%;
        margin-top: 20px;
        height: calc(100% - 40px);
        overflow-y: auto;

        /deep/.upload-box {
          >div:nth-child(1) {
            width: 100%;
          }
        }
      }
    }

    .sPage {
      width: 80%;
      margin-left: 10%;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #efefe0;

      .tPage {
        margin: 10px;
      }

      .uPage {
        .star {
          text-align: center;

          .number {
            font-size: 12px;
            color: #666;
          }
        }
      }

      .vPage {
        overflow-y: auto;
        height: 77%;
      }
    }

    .nowToUp {
      display: flex;
      height: 40px;
      width: 80%;
      margin-left: 10%;
      margin-bottom: 20px;

      .check {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button {
        flex: 1;
      }
    }
  }
}
</style>