<template>
  <div style="height: 100%">
    <van-nav-bar
      :title="showTitle()"
      left-text="退出"
      left-arrow
      @click-left="back"
    />
    <router-view style="height: calc(100% - 96px)" />
    <van-tabbar v-model="active">
      <van-tabbar-item
        v-for="it in list"
        :key="it.name"
        :name="it.name"
        :icon="it.icon"
        @click="go(it.name)"
        >{{ it.tag }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "search",
      list: [
        { name: "search", icon: "search", tag: "搜索" },
        { name: "star", icon: "star-o", tag: "收藏" },
        { name: "upgrade", icon: "upgrade", tag: "发布" },
        { name: "setting", icon: "setting-o", tag: "设置" },
      ],
    };
  },
  watch: {
    // 监听路由对象中的变化
    $route: {
      handler: function (to) {
        this.active = to.name;
      },
      immediate: true,
    },
  },
  methods: {
    go(router) {
      let route = this.$route.name;
      if (router != route) {
        this.$router.push(router);
      }
    },
    showTitle() {
      let route = this.$route.name;
      let has = this.list.find((a) => a.name == route);
      if (has) {
        return has.tag;
      } else {
        return "";
      }
    },
    back() {
      this.$router.push('login');
    },
  },
};
</script>