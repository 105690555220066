<template>
  <div class="page">
    <van-nav-bar
      title="注册账号"
      left-text="返回"
      left-arrow
      @click-left="backOn"
    />
    <div class="box">
      <div class="formBox">
        <el-form
          :model="signForm"
          :rules="rules"
          ref="signForm"
          label-width="6em"
        >
          <el-form-item label="账号" prop="sAccount">
            <el-input v-model="signForm.sAccount" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="sPassword">
            <el-input
              v-model="signForm.sPassword"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="surePassword">
            <el-input
              v-model="signForm.surePassword"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="sUserName">
            <el-input
              v-model="signForm.sUserName"
              clearable
              maxlength="12"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="邀请码1" prop="Invite1">
            <el-input v-model="signForm.Invite1" clearable></el-input>
          </el-form-item>
          <el-form-item label="邀请码2" prop="Invite2">
            <el-input v-model="signForm.Invite2" clearable></el-input>
          </el-form-item>
          <el-form-item label="邀请码3" prop="Invite3">
            <el-input v-model="signForm.Invite3" clearable></el-input>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('signForm')"
            >注册账号</el-button
          >
        </el-form>
      </div>
    </div>
    <rs-puzzle :show="isShow" @success="trySign" @close="isShow = false" />
  </div>
</template>

<script>
import { Notify } from "vant";
import rsPuzzle from "rs-puzzle";
export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.signForm.sPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      signForm: {
        sAccount: "",
        sPassword: "",
        surePassword: "",
        sUserName: "",
        Invite1: "",
        Invite2: "",
        Invite3: "",
      },
      isShow: false,
      rules: {
        sAccount: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]{4,12}$/,
            message: "格式为4至12位下划线、英文及数字组合",
            trigger: "blur",
          },
        ],
        sPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{4,16}$/,
            message: "4-16位组合,至少包含一个字母和数字",
            trigger: "blur",
          },
        ],
        surePassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: validatePass2,
            trigger: ["change", "blur"],
          },
        ],
        sUserName: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 1, max: 7, message: "长度不能大于 7 个字符", trigger: "blur" },
        ],
        Invite1: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
        Invite2: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
        Invite3: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
      },
    };
  },
  components: {
    rsPuzzle,
  },
  methods: {
    backOn() {
      this.$router.go(-1);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isShow = true;
        } else {
          return false;
        }
      });
    },
    trySign() {
      this.$reqTools
        .request(
          "Sign",
          {
            sAccount: this.signForm.sAccount,
            sPassword: this.signForm.sPassword,
            sUserName: this.signForm.sUserName,
            Invite1: this.signForm.Invite1,
            Invite2: this.signForm.Invite2,
            Invite3: this.signForm.Invite3,
          },
          false
        )
        .then((res) => {
          this.isShow = false;
          if (res.code == 200) {
            localStorage.setItem(
              "photoKeep",
              JSON.stringify({
                sName: this.signForm.sAccount,
                sPass: this.signForm.sPassword,
              })
            );
            Notify({ type: "success", message: "注册成功" });
            this.backOn();
          } else {
            Notify({ type: "danger", message: res.msg });
          }
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .formBox {
      width: 300px;
    }
  }
}
</style>