<template>
  <div>
    <van-overlay :show="show">
      <div class="box">
        <div class="block">
          <div class="loading">
            <span style="--i: 0"></span> <span style="--i: 1"></span>
            <span style="--i: 2"></span> <span style="--i: 3"></span>
            <span style="--i: 4"></span> <span style="--i: 5"></span>
            <span style="--i: 6"></span> <span style="--i: 7"></span>
          </div>
          <div class="text">
            <div>已加载</div>
            <div>{{ loaded }}/{{ all }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Number,
      default: 0,
    },
    all: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-overlay {
  background-color: rgba($color: #fff, $alpha: 0);
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    background-color: rgba($color: #000, $alpha: 0.6);
    border-radius: 10px;
  }
}

.loading {
  width: 140px;
  height: 90px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: contrast(15);
}

.loading span {
  position: absolute;
  left: 40px;
  transform-origin: 30px center;
  transform: rotate(0deg) translateX(85px);
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: show 3s infinite;
}

@keyframes show {
  0% {
    transform: rotate(0deg) translateX(10px);
  }

  50%,
  100% {
    transform: rotate(calc(360deg / 8 * var(--i)));
  }

  100% {
    transform: rotate(360deg) translateX(10px);
  }
}

.text {
  display: flex;
  justify-content: space-around;
  color: #fff;
  height: 50px;
  line-height: 50px;
}
</style>