<template>
  <div class="page">
    <div class="cell">
      <van-cell-group inset style="margin-top: 10px">
        <van-cell
          title="昵称"
          :value="info.sUserName"
          is-link
          @click="(show = true), (username = info.sUserName)"
        />
        <van-cell title="金币" :value="info.iCoin" />
        <van-cell title="今日赞" :value="info.iTodayUp" />
        <van-cell title="今日踩" :value="info.iTodayDown" />
        <van-cell title="总赞数" :value="info.iUp" />
        <van-cell title="总踩数" :value="info.iDown" />
      </van-cell-group>
      <van-cell-group style="margin-top: 10px" inset>
        <van-cell value="修改密码" is-link @click="setPassword" />
      </van-cell-group>
      <van-cell-group style="margin-top: 10px" inset>
        <van-cell value="购买邀请码" is-link @click="getInvite" />
      </van-cell-group>
    </div>
    <transition name="el-zoom-in-bottom">
      <div v-show="passwordPage" class="ePage">
        <div class="top">
          <div class="closeButton" @click="passwordPage = false">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="dPage">
          <div class="fPage">
            <el-form
              :model="setPasswordForm"
              :rules="rules"
              ref="setPasswordForm"
              label-width="6em"
            >
              <el-form-item label="密码" prop="sPassword">
                <el-input
                  v-model="setPasswordForm.sPassword"
                  clearable
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="surePassword">
                <el-input
                  v-model="setPasswordForm.surePassword"
                  clearable
                  show-password
                ></el-input>
              </el-form-item>
              <el-button
                type="primary"
                style="width: 100%"
                @click="submitForm('setPasswordForm')"
                >修改密码</el-button
              >
            </el-form>
          </div>
        </div>
      </div>
    </transition>
    <transition name="el-zoom-in-bottom">
      <div v-show="invitePage" class="ePage">
        <div class="top">
          <div class="closeButton" @click="closeInvitePage">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="dPage">
          <div class="fPage">
            <van-cell-group inset>
              <van-cell v-for="(item, index) in myInvite" :key="index">
                <template #title>
                  <div class="rows">
                    <div class="code">{{ item.sInviteCode }}</div>
                    <div class="hasUsed" v-if="item.bUsed == 1">
                      <img
                        :src="require('@/assets/used.png')"
                        alt=""
                        width="60px"
                      />
                    </div>
                  </div>
                </template>
                <template #right-icon>
                  <el-button
                    type="primary"
                    size="mini"
                    :disabled="item.bUsed == 1"
                    @click="copy(item.sInviteCode)"
                    >复制</el-button
                  >
                </template>
              </van-cell>
            </van-cell-group>
          </div>
          <div class="push">
            <i class="el-icon-circle-plus" @click="buyInvite"></i>
          </div>
        </div>
      </div>
    </transition>
    <van-dialog
      v-model="show"
      title="修改昵称"
      show-cancel-button
      @confirm="setUsername"
    >
      <div style="margin: 10px 5px">
        <el-input
          v-model="username"
          placeholder="请输入新昵称"
          maxlength="12"
          show-word-limit
          clearable
        ></el-input>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Notify } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.setPasswordForm.sPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      info: {},
      show: false,
      username: "",
      passwordPage: false,
      invitePage: false,
      setPasswordForm: {
        sPassword: "",
        surePassword: "",
      },
      checked: false,
      // 假设有某种条件决定是否禁用
      condition: true,
      rules: {
        sPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{4,16}$/,
            message: "4-16位组合,至少包含一个字母和数字",
            trigger: "blur",
          },
        ],
        surePassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: validatePass2,
            trigger: ["change", "blur"],
          },
        ],
      },
      myInvite: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async copy(text) {
      let copyInput = document.createElement("input");
      copyInput.value = text;
      copyInput.setAttribute("readonly", "readonly"); //避免ios端聚焦引起的白屏抖动
      document.body.appendChild(copyInput); //插入body
      copyInput.select(); //选择对象
      // copyInput.setSelectionRange(0, link.length);//ios端使用setSelectionRange避免选择不全问题
      document.execCommand("Copy"); //执行复制命令
      this.copySuccess = true;
      copyInput.remove(); //移除
      Notify({ type: "success", message: "内容已复制到剪贴板" });
    },
    closeInvitePage() {
      this.invitePage = false;
      this.getInfo();
    },
    getInfo() {
      this.$reqTools.request("Info").then((res) => {
        if (res.code != 200) {
          Notify({ type: "danger", message: res.msg });
          this.$router.push("login");
        } else {
          this.info = res.data;
        }
      });
    },
    setPassword() {
      this.setPasswordForm.sPassword = "";
      this.setPasswordForm.surePassword = "";
      this.passwordPage = true;
    },
    getInvite() {
      this.$reqTools.request("myInvite").then((res) => {
        this.myInvite = res.data;
        this.invitePage = true;
      });
    },
    buyInvite() {
      Dialog.confirm({
        title: "提示",
        message: "是否花费 100 金币购买 1 个邀请码？",
      })
        .then(() => {
          this.$reqTools.request("buyInvite").then((res) => {
            if (res.code != 200) {
              Notify({ type: "danger", message: res.msg });
            } else {
              Notify({ type: "success", message: res.msg });
              this.getInvite();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },
    setUsername() {
      if (this.username == "") {
        Notify({ type: "danger", message: "昵称输入不能为空" });
      } else {
        this.$reqTools
          .request("setUsername", { sUserName: this.username })
          .then((res) => {
            if (res.code != 200) {
              Notify({ type: "danger", message: res.msg });
              this.$router.push("login");
            } else {
              Notify({ type: "success", message: "修改成功" });
              this.show = false;
              this.getInfo();
            }
          });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          Dialog.confirm({
            title: "提示",
            message: "是否确认修改密码",
          })
            .then(() => {
              this.$reqTools
                .request("setPassword", {
                  sPassword: this.setPasswordForm.sPassword,
                })
                .then((res) => {
                  if (res.code != 200) {
                    Notify({ type: "danger", message: res.msg });
                    this.$router.push("login");
                  } else {
                    Notify({ type: "success", message: "密码修改成功" });
                    this.passwordPage = false;
                    this.$router.push("login");
                  }
                });
            })
            .catch(() => {
              return false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  position: relative;
  flex-direction: column;
  .cell {
    height: 100%;
  }
  .ePage {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      justify-content: right;
      margin-right: 5px;
      .closeButton {
        border-left: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-radius: 0 0 10px 10px;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 25px;
      }
    }
    .dPage {
      flex: 1;
      overflow-y: auto;
      position: relative;
      .fPage {
        width: 80%;
        margin-left: 10%;
        margin-top: 5vh;
        height: 75%;
        overflow-y: auto;
      }
      .push {
        font-size: 50px;
        color: #409eff;
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.rows {
  height: 28px;
  position: relative;
  .code {
    line-height: 28px;
  }
  .hasUsed {
    position: absolute;
    top: -16px;
    left: 20px;
    .img {
      width: 28px;
    }
  }
}
</style>