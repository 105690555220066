<template>
  <div class="page">
    <div class="searchBox">
      <el-input v-model="photoCode" placeholder="请先输入图片码" clearable></el-input>
      <el-button type="primary" icon="el-icon-search" style="width: 70%; margin-left: 15%; margin-top: 20px"
        @click="search">搜索</el-button>
    </div>
    <transition name="el-zoom-in-bottom">
      <div v-show="show" class="ePage">
        <div class="top">
          <div class="closeButton" @click="closeSearch">
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div class="sPage">
          <div class="tPage">
            {{ detail.sTitle }}
          </div>
          <div class="uPage">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px" v-if="own">
                    <van-icon name="star" />
                  </div>
                  <div class="like" v-else>
                    <like-effects width="25px" :checked="detail.star">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="star"
                          @click="starIt" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px"><van-icon name="star"
                          @click="starIt" /></span>
                    </like-effects>
                  </div>
                  <div class="number">{{ detail.starNumber }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px" v-if="own">
                    <van-icon name="good-job" />
                  </div>
                  <div class="like" v-else>
                    <like-effects width="25px" :checked="detail.up">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="good-job"
                          @click="hasLike(true)" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px"><van-icon name="good-job"
                          @click="hasLike(true)" /></span>
                    </like-effects>
                  </div>
                  <div class="number">
                    {{ detail.upNumber }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="star">
                  <div class="disabled" style="color: gray; font-size: 25px" v-if="own">
                    <van-icon name="delete" />
                  </div>
                  <div class="like" v-else>
                    <like-effects width="25px" :checked="detail.down">
                      <span slot="unchecked" style="color: gray; font-size: 25px"><van-icon name="delete"
                          @click="hasLike(false)" /></span>
                      <span slot="checked" style="color: orange; font-size: 25px">
                        <van-icon name="delete" @click="hasLike(false)" />
                      </span>
                    </like-effects>
                  </div>
                  <div class="number">{{ detail.downNumber }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="vPage">
            <van-image style="padding: 5px" width="calc(100% - 10px)" v-for="(item, index) in imageList" :key="index"
              :src="item" @click="showBig(index)" loading-icon="clock" />
          </div>
        </div>
      </div>
    </transition>
    <Loading :show.sync="ifLoad" :loaded.sync="unit" :all.sync="imageList.length" />
  </div>
</template>

<script>
import { Notify } from "vant";
import { ImagePreview } from "vant";
import { Toast } from "vant";
import "like-effects";
import Loading from "../comm/loading";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      photoCode: "",
      show: false,
      detail: {
        sTitle: "",
        star: false,
        starNumber: 0,
        up: false,
        upNumber: 0,
        down: false,
        downNumber: 0,
        sCode: "",
      },
      imageList: [],
      own: true,
      ifLoad: false,
      cancelTokens: [],
      unit: 0,
    };
  },
  beforeDestroy() {
    if (this.imageList.length != 0) {
      for (const item in this.imageList) {
        URL.revokeObjectURL(item);
      }
    }
    this.cancelTokens.forEach((source) => {
      source.cancel("Component is being destroyed");
    });
  },
  methods: {
    starIt() {
      let current = this.detail.star;
      this.$reqTools
        .request("star", { sCode: this.detail.sCode })
        .then((res) => {
          if (res.code != 200) {
            this.detail.star = current;
          } else {
            this.detail.star = !current;
            if (this.detail.star) {
              this.detail.starNumber++;
            } else {
              this.detail.starNumber--;
            }
          }
        });
    },
    search() {
      if (this.photoCode == "") {
        Notify({ type: "danger", message: "输入内容不能为空" });
      } else {
        this.ifLoad = true;
        this.showDetail(this.photoCode);
      }
    },
    hasLike(unt) {
      const upTemp = this.detail.up;
      const downTemp = this.detail.down;
      this.$reqTools
        .request("like", { sCode: this.detail.sCode, like: unt })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            res = res.data;
            if (res.up != undefined) {
              this.$set(this.detail, "up", res.up);
            }
            if (res.down != undefined) {
              this.$set(this.detail, "down", res.down);
            }
            if (res.up == false && res.down == undefined) {
              this.detail.upNumber--;
            } else if (res.up == true) {
              if (downTemp) {
                this.detail.downNumber--;
              }
              this.detail.upNumber++;
            } else if (res.down == false && res.up == undefined) {
              this.detail.downNumber--;
            } else if (res.down == true) {
              if (upTemp) {
                this.detail.upNumber--;
              }
              this.detail.downNumber++;
            }
          } else {
            this.$set(this.detail, "up", upTemp);
            this.$set(this.detail, "down", downTemp);
          }
        });
    },
    closeSearch() {
      if (this.imageList.length != 0) {
        for (const item in this.imageList) {
          URL.revokeObjectURL(item);
        }
      }
      this.imageList = [];
      this.show = false;
    },
    async showDetail(sCode) {
      this.ifLoad = true;
      this.unit = 0;
      this.$reqTools.request("showDetail", { sCode: sCode }).then((res) => {
        if (res.code != 200 || res.data.length == 0) {
          this.ifLoad = false;
          Toast({
            message: "无效图片码",
            icon: "cross",
          });
        } else {
          this.detail = res.data.stats;
          let photo = res.data.sPhoto.sort(
            (a, b) => a.split(".")[0] - b.split(".")[0]
          );
          this.own = res.data.own;
          this.getPhoto(sCode, photo, this.unit)
          if (photo.length != 0) {
            this.imageList = new Array(photo.length);
          }
        }
      });
    },
    async getPhoto(sCode, photo, unit) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.cancelTokens.push(source);
      await this.$reqTools
        .blob("getPhoto", { sCode: sCode, imageName: photo[unit] }, source)
        .then((blob) => {
          let temp = URL.createObjectURL(blob);
          this.imageList.splice(unit, 1, temp);
          let has = this.imageList.filter(Boolean);
          this.unit++;
          if (has.length == photo.length) {
            this.show = true;
            this.ifLoad = false;
          }
          else {
            this.getPhoto(sCode, photo, this.unit)
          }
        });
    },
    showBig(index) {
      ImagePreview({
        images: this.imageList,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  position: relative;

  .searchBox {
    width: 80%;
    margin-left: 10%;
    margin-top: 20vh;
  }

  .ePage {
    position: absolute;
    top: 0;
    height: calc(100% - 31px);
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: right;
      margin-right: 5px;

      .closeButton {
        border-left: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-radius: 0 0 10px 10px;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 25px;
      }
    }

    .dPage {
      flex: 1;
      overflow-y: auto;

      .fPage {
        width: 80%;
        margin-left: 10%;
        height: 2000px;
      }
    }

    .sPage {
      flex: 1;
      width: 80%;
      margin-left: 10%;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #efefe0;

      .tPage {
        margin: 10px;
        height: 20px;
      }

      .uPage {
        height: 50px;

        .star {
          text-align: center;

          .number {
            font-size: 12px;
            color: #666;
          }
        }
      }

      .vPage {
        margin-top: 5px;
        height: calc(100% - 30px - 45px);
        overflow-y: auto;
      }
    }
  }
}
</style>