import Vue from 'vue'
import VueRouter from 'vue-router'

import loginPage from '@/components/login'
import signPage from '@/components/sign'
import main from '@/components/main'

import search from '@/components/main/search'
import star from '@/components/main/star'
import upgrade from '@/components/main/upgrade'
import setting from '@/components/main/setting'



Vue.use(VueRouter)

const routes = [
    {
        path: '/sign',
        name: 'sign',
        component: signPage
    },
    {
        path: '/main',
        name: 'main',
        component: main,
        redirect: "search",
        children: [
            {
                path: '/search',
                name: 'search',
                component: search
            },
            {
                path: '/star',
                name: 'star',
                component: star
            },
            {
                path: '/upgrade',
                name: 'upgrade',
                component: upgrade
            },
            {
                path: '/setting',
                name: 'setting',
                component: setting
            },
        ]
    },
    {
        path: '*',
        name: 'login',
        component: loginPage
    },
]

const router = new VueRouter({
    routes
})

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
    next();
})

export default router

